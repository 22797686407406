import React, { useState, useEffect, useRef } from 'react'
import HeroText from './HeroText'

const IntroBox = (props) => {
    const [msgCount, setMsgCount] = useState(1);
    const [showApply, setShowApply] = useState(false)
    const [endIntro, setEndIntro] = useState(false)
    const [lines, setLines] = useState([
        { line: "HARD TIMES", },
        { line: "MAKE", },
        { line: "GREAT PEOPLE.", }
    ])

    useEffect(() => {
        let count = 0;
        let time = 0;
        let newObj = {};

        if (msgCount === 1) {
            setTimeout(() => {
                props.vidBGRef.current.pause();
            }, 17100);
        }

        const endIntro = () => {
            setTimeout(() => {
                props.setApplyVis2("easeIt")
                props.setChevron("chevron")
                props.setFadeArrow("opacity-0")
            }, 7500);

            setTimeout(() => {
                props.logoFlasher('logoFlashLittle', 1)

            }, 5000);

            setTimeout(() => {
                setEndIntro(true)
                // props.setMask('fadeOut')

            }, 4000);

            setTimeout(() => {
                props.setApplyVis("easeIt")
                props.setPulse(true)
            }, 3000);

        }

        const updateLines = (ct) => {
            switch (ct) {
                case 1:
                    setLines([
                        { line: "GREAT PEOPLE", class: 'opacity-0' },
                        { line: "MAKE", class: 'opacity-0' },
                        { line: "GREAT PIZZA.", class: 'opacity-0' }
                    ])
                    break;
                case 2:
                    setLines([
                        { line: "GREAT PEOPLE", class: '' },
                        { line: "MAKE", class: '' },
                        { line: "GREAT PIZZA.", class: '' }
                    ])
                    break;
                case 3:
                    setLines([
                        { line: "WE'RE", class: 'opacity-0' },
                        { line: "SEEKING", class: 'opacity-0' },
                        { line: "GREAT PEOPLE.", class: 'opacity-0' }
                    ])
                    break;
                default:
                    break;
            }
        }


        const fadeTextOut = (newLines) => {
            if (!newLines) {
                newLines = [...lines]
            }
            newObj = { ...lines[count], class: 'heroTxt' }
            if (msgCount === 3 && count > 3) {
                setTimeout(() => {
                    updateLines(msgCount)
                    setMsgCount(msgCount + 1)

                }, 1000);

            } else {
                setTimeout(() => {
                    newLines = newLines.map(item => item.line !== newObj.line ? item : newObj)
                    setLines([...newLines])

                    if (count <= 3) {
                        time += 300;
                        count++
                        fadeTextOut(newLines);

                    } else {

                        updateLines(msgCount);
                        setMsgCount(msgCount + 1)

                    }
                }, time);
            }
        }

        const fadeTextIn = (newLines) => {
            if (!newLines) {
                newLines = [...lines]
            }
            newObj = { ...lines[count], class: 'heroTxtIn' }
            if (msgCount === 4) {
                newObj.class = ""
            }
            setTimeout(() => {
                newLines = newLines.map(item => item.line !== newObj.line ? item : newObj)
                setLines([...newLines])
                if (count <= 3) {
                    time += 300;
                    if (msgCount === 4) {
                        time += 300;
                        endIntro();
                    }
                    count++
                    fadeTextIn(newLines);


                } else {
                    updateLines(msgCount)
                    setMsgCount(msgCount + 1)

                }
            }, time);
        }


        switch (msgCount) {
            case 1:
                setTimeout(() => {
                    fadeTextOut(lines)
                }, 1000);
                break;
            case 2:
                setTimeout(() => {
                    fadeTextIn(lines)
                }, 500);
                break;
            case 3:
                setTimeout(() => {
                    fadeTextOut(lines)
                }, 500);
                break;
            case 4:
                setTimeout(() => {
                    fadeTextIn(lines)
                }, 500);
            default:
                break;
        }
    }, [msgCount])

    return (
        <div className='flex flex-col w-full justify-center z-40 absolute h-screen '>
            <HeroText
                endIntro={endIntro}
            >
                {lines.map(item => <div className=' flex justify-center '> <h1 className={`text-center txtShadow my-5 font-bioRhyme text-boomsWhite text-3xl md:text-4xl  my-2 ${item.class}`}>{item.line} </h1></div>)}
            </HeroText>
        </div>
    )
}

export default IntroBox