import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import video from './assets/newPizzaBG.mp4'
import video2 from './assets/newPizzaBG2.mp4'
import mainLogo from './assets/mainLogo.png'
import rightChevron from './assets/rightChevron.png'
import IntroBox from './components/IntroBox';
import Mask from './components/Mask';
import IconButton from './components/IconButton'
import Arrow from './components/Arrow'
import FacebookIcon from './assets/icons8-facebook-75.png'
import InstagramIcon from './assets/icons8-instagram-75.png'

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [hero, setHero] = useState({
    links: "",
    logo: "",
    btn: "",
    more: ""
  })
  const [windowSize, setWindowSize] = useState()
  const [mask, setMask] = useState('')
  const [logoFlash, setLogoFlash] = useState('')
  const [logoHide, setLogoHide] = useState('logoOut')
  const [pulse, setPulse] = useState(false)
  const [chevron, setChevron] = useState('')
  const [applyVis, setApplyVis] = useState("opacity-0")
  const [applyVis2, setApplyVis2] = useState("opacity-0")
  const [fadeArrow, setFadeArrow] = useState("")
  const [arrowSize, setArrowSize] = useState({
    width: 180,
    height: 58,
    position: 'lgArrow',
    show: 'hidden'
  })


  const vidBGRef = useRef(null)

  const getWindowSize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {

    if (windowSize?.width > 1024) {
      setArrowSize({ width: 180, height: 58, position: 'lgArrow', show: '' })
    }

    if (windowSize?.width < 1024) {
      setArrowSize({ width: 150, height: 48, position: 'mdArrow', show: '' })
    }

    if (windowSize?.width < 575) {
      setArrowSize({ width: 90, height: 29, position: 'smArrow', show: '' })
    }

  }, [windowSize])

  useEffect(() => {

    if (windowSize?.height) {
      console.log(windowSize.height)
      let base = windowSize.height / 100;
      let links = base * 2;
      let logo = base * 12;
      let btn = base * 70;
      let more = base * 88;

      setHero({
        links: links.toString(),
        logo: logo.toString(),
        btn: btn.toString(),
        more: more.toString()
      })
    }

  }, [windowSize])

  useEffect(() => {
    setWindowSize(getWindowSize());
  }, [])


  useEffect(() => {
    setTimeout(() => {
      setMask('fadeOut')
      vidBGRef.current.play();

    }, 150);
  }, [])

  const logoFlasher = (flashing, count) => {
    setLogoHide('')
    let logocount = count;
    let isFlashing = flashing


    if (count > 3) {
      setLogoFlash('logoFlashLittle')
      setTimeout(() => {
        setLogoFlash('logoFlashOut')
      }, 500);
      setTimeout(() => {
        setLogoFlash('')
      }, 1000);
      return;
    }


    setTimeout(() => {

      logocount++
      if (isFlashing === 'logoFlashBig') {
        setLogoFlash('logoFlashLittle')
        isFlashing = 'logoFlashLittle';
      } else {
        setLogoFlash('logoFlashBig')
        isFlashing = 'logoFlashBig'
      }
      logoFlasher(isFlashing, logocount)
    }, 700);
  }



  return (

    <div className="App relative flex flex-col h-screen items-center">
      <Mask mask={mask} />
      <div className='z-50 h-screen w-full flex flex-col relative items-center'>
        <div id="linksBox" className={`z-50 flex absolute`} style={{ top: `${hero.links}px` }}>
          <a href="https://www.facebook.com/boomspizza"><img className='w-12 md:w-full' src={FacebookIcon} /></a>
          <a href="https://www.instagram.com/boomspizza/?hl=en"><img className='w-12 md:w-full' src={InstagramIcon} /> </a>
        </div>
        <div id="logoBox" className={`flex flex-col absolute items-center ${logoHide}`} style={{ top: `${hero.logo}px` }}>
          <img id="logo" src={mainLogo} className={`z-20 md:w-3/4 lg:w-1/2 relative md:right-8 linear  ${logoFlash}`} />
          <h1 id="subText" className={`z-50 text-center textShadow mt-8 mx-3 font-bioRhyme text-xl text-boomsWhite relative linear  `}>COMING SOON TO A PIZZA PARTY NEAR YOU</h1>
        </div>
        <IntroBox
          windowSize={windowSize}
          setApplyVis={setApplyVis}
          setApplyVis2={setApplyVis2}
          setFadeArrow={setFadeArrow}
          setPulse={setPulse}
          setMask={setMask}
          logoFlasher={logoFlasher}
          setChevron={setChevron}
          vidBGRef={vidBGRef}
        />
        <div id="buttonBox" className={`flex z-50 w-full absolute justify-center ${applyVis}`} style={{ top: `${hero.btn}px` }}>
          <Arrow
            fadeArrow={fadeArrow}
            pulse={pulse}
            setPulse={setPulse}
            arrowSize={arrowSize}
            setArrowSize={setArrowSize}
          />
          <IconButton text="Apply Here!" />
        </div>
        <div id="seeMoreBox" className={`z-50 absolute  flex flex justify-center items-center ${applyVis2}`} style={{ top: `${hero.more}px` }}>
          <a href='https://www.boomspizza.com' ><h6 className='text-center  font-bioRhyme text-base textShadow text-boomsWhite hover:text-boomsYellow active:text-accentRed '>Or just learn more about our pizza</h6></a>
          <div className='flex'>
            <img className={`mt-3 w-4 h-4 mb-3 ml-3 ${chevron}`} src={rightChevron} />
          </div>

        </div>
        <div id="vidContainer">
          <video muted loop playsInline alt="pizza background" id="heroBG" ref={vidBGRef} className=''>
            <source src={video2} type="video/mp4" />
          </video>
        </div>
      </div>
      <a className='hidden'><a target="_blank" href="https://icons8.com/icon/32292/instagram">Instagram</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a></a>
    </div>
  );
}

export default App;
